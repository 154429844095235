import React from 'react';
import LogoEditorial from '../../images/logo_cardinal.svg';


import './Header.scss';

const Header = () => {
    return (
        <div className='menu_superior'>
            <div className='logo'>
                <img src={LogoEditorial} alt={LogoEditorial} />
            </div>
            <div className='datos_menu'>
                <div className='title_text'>
                    <p>Comprensión de lectura 2 - Lectura 5</p>
                </div>
                <div className='activity'>
                    <p><strong>Actividad: </strong>Encuentra a todos los Rupelstinki en la imagen.</p>
                </div>
            </div>
        </div>
    )
}

export default Header