import React, {useState, useEffect} from 'react'; 
import Fondo_image from '../../images/fondo_premio.png';
import Titulo from '../../images/titulo_premio.png';
import Cerrar from '../../images/btn_cerrar.png';
import { useSpring, animated } from '@react-spring/web';


import './Premios.scss';
const arayImages = require.context('../../images', true);

function Premios() {
	const [img, setImg] = useState('premios_1.png');

	const imagenes2 = [
		{
			imagen:'premios_1.png'
		},
		{
			imagen:'premios_2.png'
		},
		{
			imagen:'premios_3.png'
		},
		{
			imagen:'premios_4.png'
		},
		{
			imagen:'premios_5.png'
		},
		{
			imagen:'premios_6.png'
		},
		{
			imagen:'premios_7.png'
		},
		{
			imagen:'premios_8.png'
		}		
	]	

	const cambioImage = () => {
		function shuffle(arr) {
			let i,
				j,
				temp;
			for (i = arr.length - 1; i > 0; i--) {
				j = Math.floor(Math.random() * (i + 1));
				temp = arr[i];
				arr[i] = arr[j];
				arr[j] = temp;
			}
			return arr;    
		};

		const arrayNuevo = shuffle(imagenes2);
		setImg(arrayNuevo[0].imagen)
	}

	const springs = useSpring({
		from: { opacity: 0 },
		to: { opacity: 1 },
	});

	const gameOver = () => {
		console.log("cerrando");
		window.location.replace('/');
	}

	useEffect(() => {
		cambioImage();
	}, [])

	return (
		<animated.div style={springs} className="contenido_premio">
			<img className='fondo' src={Fondo_image} alt='iamgen de fondo'/>
			<img className='titulo' src={Titulo} alt='imagen_titulo'/>
			<img className='premio' src={arayImages(`./${img}`)} alt='premio'/>
			<img className='btn_cerrar' onClick={() => gameOver()} src={Cerrar} alt='btn'/>
		</animated.div>
	)
}

export default Premios